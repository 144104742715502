import { graphql } from 'gatsby';
import React, { FC } from 'react';

import SEO from 'src/components/Seo/Seo';
import Banner from 'src/components/Banner';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';

interface PrivacyPolicyProps {
  data: any;
}

const PrivacyPolicyPage: FC<PrivacyPolicyProps> = ({ data }) => {
  const {
    wpPage: { privacyPolicyFields: acfFields, seo },
    banner,
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={seo.opengraphImage}
      />

      <Banner
        image={banner.childImageSharp.gatsbyImageData}
        title="Privatumo politika"
      />
      <Main>
        <div dangerouslySetInnerHTML={{ __html: acfFields.main }} />
        <div
          id="asmeniniai-duomenys"
          dangerouslySetInnerHTML={{ __html: acfFields.bdar }}
        />
        <div
          id="slapukai"
          dangerouslySetInnerHTML={{ __html: acfFields.cookies }}
        />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query PrivacyPolicy {
    wpPage(slug: { eq: "privatumo-politika" }) {
      ...YoastSeoPage
      privacyPolicyFields {
        main
        bdar
        cookies
      }
    }
    banner: file(relativePath: { eq: "pirkimo-salygos.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

export default PrivacyPolicyPage;
